import * as React from "react";
import Layout from "../_components/Layout/Layout";
import ErrorMessage from "../_components/ErrorMessage/ErrorMessage";

const Problem = ({ content }) => {
    return (
        <Layout hideFooterNavigation={true} title="Something went wrong!">
            <ErrorMessage content={content}/>
        </Layout>
    );
};
export default Problem;
